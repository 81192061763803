import React, { useContext } from 'react'
import './style.scss'
import NaviContext from '../../../../context/NaviContext'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

import DeepLink from '../../../DeepLink'

function validURL(str) {
	var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
		'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(str);
}

function NaviItem(props) {
	const naviContext = useContext(NaviContext)

	// // disabled flag
	// let enabled = true
	// const classes = props.classes?.map((cssClass) => {
	// 	// if one of the classes is disabled, set the flag
	// 	if (cssClass === 'disabled') {
	// 		enabled = false
	// 	}
	// 	// if it has description, add the hint classes
	// 	if (description) {
	// 		return cssClass + ' hint--rounded hint--bottom'
	// 	} else {
	// 		return cssClass
	// 	}
	// }).join(' ')

	const dropDownClickHandle = props.dropDownClickHandle

  const title = props.title
  const link = props.link
  const slug = props.slug?.current
  const type = props._type
  const forceExternal = props.forceExternal
  const id = props.id


  let finalized = null

  switch (type) {
    case 'link':
      finalized = (link && validURL(link)) || forceExternal ? <a href={link} target="_blank" rel="noreferrer noopener"><span>{title}</span></a> : link ? <DeepLink to={link}><span>{title}</span></DeepLink> : null
      break
      
    case 'page':
      finalized = forceExternal ? <a href={`/${slug}/`} target="_blank" rel="noreferrer noopener"><span>{title}</span></a> : <DeepLink to={`/${slug}`}><span>{title}</span></DeepLink>
      break
      
    case 'post':
      finalized = forceExternal ? <a href={`/post/${slug}/`} target="_blank" rel="noreferrer noopener"><span>{title}</span></a> : <DeepLink to={`/post/${slug}`}><span>{title}</span></DeepLink>
      break

    case 'category':
      finalized = forceExternal ? <a href={`/category/${slug}/`} target="_blank" rel="noreferrer noopener"><span>{title}</span></a> : <DeepLink to={`/category/${slug}`}><span>{title}</span></DeepLink>
      break

    default:
      break
  }

		return (
			<div
				key={id}
				className={`nav-item x1 t`}
        onClick={() => { naviContext.setHamburgerActive(false); naviContext.activeToggle(false); dropDownClickHandle(); }}
				onKeyDown={() => { naviContext.setHamburgerActive(false); naviContext.activeToggle(false); dropDownClickHandle(); }}
			>
        {finalized}
			</div>
		)
	
}

export default NaviItem