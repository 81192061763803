import React from 'react'
import {  Link } from "gatsby"
import { useLanguageContext } from '../../context/LanguageContext'

function DeepLink(props) {
	
	const languageContext = useLanguageContext()

	let {
		to
	} = props

	let prefix = to.split('/')?.[1]
	
	let finalUrl = 
		prefix === languageContext?.languageShort || languageContext?.isBase ?
			to
		:
			'/' + languageContext?.languageShort + to

	
  return (
		<Link {...props} to={finalUrl}>
			{props.children}
		</Link>
  )
}

export default DeepLink