import React, { useContext, useState, useEffect } from 'react'
import './style.scss'
import NaviContext from '../../context/NaviContext'
import { Transition } from 'react-transition-group'
import anime from 'animejs'

import Hamburger from './Hamburger'
import NaviPrimary from './Navi/Primary'
import SocialIcons from '../SocialIcons'

function Header(props) {
  const naviContext = useContext(NaviContext)
  const [naviBg, setNaviBg] = useState('x0 t')

  // Animating fade in/out
  const baseDuration = 250
  const fadeInLogo = element => {
    const links = element.querySelectorAll('.nav-item, .social-icons a')
    anime
      .timeline()
      .add({
        targets: element,
        opacity: [0, 1],
        duration: baseDuration,
        easing: 'easeInOutSine',
      })
      .add({
        targets: links,
        opacity: [0, 1],
        translateX: [-22, 0],
        duration: baseDuration,
        easing: 'easeInOutSine',
        delay: anime.stagger(50)
      }, `-=${baseDuration}`)
  }
  const fadeOutLogo = element => {
    anime
      .timeline()
      .add({
        targets: element,
        opacity: [1, 0],
        duration: baseDuration / 2,
        easing: 'linear'
      })
  }

  useEffect(() => {

    // Div in page.js that marks end of hero element
    const observer_target = document.getElementById('header-fold-breakpoint')

    if (observer_target) {

      // Set up what the observer will be doing
      let observer = new IntersectionObserver(function (entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setNaviBg('x0 t')
          } else {
            setNaviBg('c1')
          }
        })
      })

      // Initialize observer on the target
      observer.observe(observer_target)

      return () => {
        observer.unobserve(observer_target)
      }
    }

  }, [])

  // Fire up expanded navi on hamburger activation alone
  const naviExpanded = naviContext.isHamburgerActive

  return (
    <header className={` ${naviBg} master-header`}>
      < Hamburger baseDuration={baseDuration} />

      {/* {naviContext.beforeHeaderBreakpoint ? 'true' :	
			naviContext.scrollingDirectionIsDown ? 'false' : 'true'} */}
      {/* {naviContext.windowSize?.mobile} */}
      <Transition
        in={naviExpanded}
        timeout={baseDuration}
        appear={true}
        onEntering={fadeInLogo}
        onExiting={fadeOutLogo}
        mountOnEnter
        unmountOnExit
      >
        <div className={`master-navi c5`}>
          <NaviPrimary {...props} baseDuration={baseDuration} />
          <SocialIcons />
          <span className="close"
            onClick={() => {
              naviContext.activeToggle(false)
              naviContext.hamburgerActiveToggle(false)
            }
            }
            onKeyDown={() => {
              naviContext.activeToggle(false)
              naviContext.hamburgerActiveToggle(false)
            }
            }
          />

        </div>
      </Transition>
    </header >
  )
}

export default Header