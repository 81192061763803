import React, {useState, createContext, useEffect} from 'react'

const ModalContext = createContext(false)

function ModalContextProvider({children, location}) {
  // State for telling if the modal is active or not
	const [isActive, setActive] = useState(false)
	const [dataAttr, setDataAttr] = useState()
	const [isClosed, setIsClosed] = useState(false)
	
  /* ==========================================================================
    Modal Activity Check
  ========================================================================== */
	
  useEffect(() => {
		if(location.pathname === '/' && !isClosed){
			setDataAttr("#modal-sign-up")
			setActive(true)
		}
  },[location])

	return (
		<ModalContext.Provider value={{
      isActive,
			setIsClosed: (trueOrFalse) => setIsClosed(trueOrFalse),
			setActive: (trueOrFalse) => setActive(trueOrFalse),
			dataAttr
    }}>
      {children}
    </ModalContext.Provider>
	)
}

export default ModalContext
export { ModalContextProvider }