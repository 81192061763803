import fetch from 'isomorphic-fetch'
import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client'

const SPACE = process.env.GATSBY_CONTENTFUL_SPACE_ID
const ENVIRONMENT = process.env.GATSBY_CONTENTFUL_ENVIRONMENT
const TOKEN = process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN
const CONTENTFUL_URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE}/environments/${ENVIRONMENT}`

const client = new ApolloClient({
	link: createHttpLink({
		fetch,
		uri: CONTENTFUL_URL,
		headers: {
			authorization: `Bearer ${TOKEN}`, 
			'Content-Language': 'en-us',
		 },
	}),
	cache: new InMemoryCache()
})

export default client