import React, {useState, createContext} from 'react'

const ReuseableContext = createContext(false)

function ReuseableContextProvider({children, location}) {

  // State for current location
  const [blank, setBlank] = useState(true)


	return (
		<ReuseableContext.Provider value={{
      blank,
      setBlank: (val) => setBlank(val),
    }}>
      {children}
    </ReuseableContext.Provider>
	)
}

export default ReuseableContext
export { ReuseableContextProvider }